<form class="supy-retailer-supplier-details" [formGroup]="form">
  <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>

  <div class="supy-retailer-supplier-details__content">
    <div class="supy-retailer-supplier-details__section">
      <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

      <div class="supy-retailer-supplier-details__header">
        <h5>
          <span class="supy-retailer-supplier-details__header--title" i18n="@@suppliers.create.details"
            >Supplier Details</span
          >
        </h5>
      </div>

      <div class="supy-retailer-supplier-details__row">
        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label">
              <span i18n="@@suppliers.create.details.legalName">
                Supplier Legal Name
                <sup>*</sup>
              </span>
            </span>

            <supy-input
              #supplierNameInput
              [hidden]="!isNewSupplier() && !isExistingSupplier()"
              i18n-placeholder="@@suppliers.create.details.enterName"
              placeholder="Enter Supplier Name"
              formControlName="name"
            >
              <supy-input-hint
                (click)="searchSupplierClicked()"
                *ngIf="!isExistingSupplier()"
                class="supy-retailer-supplier-details__input--hint"
                i18n="@@suppliers.create.details.search"
              >
                Or search our suppliers
              </supy-input-hint>
            </supy-input>

            <supy-combo-box
              [hidden]="isNewSupplier() || isExistingSupplier()"
              #supplierNameCombo
              i18n-placeholder="@@suppliers.search"
              placeholder="Search Suppliers"
              [multiple]="false"
              titleKey="name"
              valueKey="id"
              displayKey="name"
              formControlName="supplier"
              [list]="supplierList()"
              (changed)="onSupplierChanged($event)"
              (searchInputUpdated)="onSearchSuppliers($event)"
              (opened)="supplierSearchTerm.set(null)"
              [isLoading]="isLoadingSuppliers"
            >
              <ng-template supyComboFooter>
                <div class="supy-retailer-supplier-details__combo--footer" *ngIf="canCreateNewSupplier">
                  <span i18n="@@suppliers.create.details.noResult">Can't find your supplier?</span>
                  <span
                    class="supy-retailer-supplier-details__combo--link"
                    (click)="createNewSupplierClicked()"
                    i18n="@@suppliers.create.details.createNew"
                    >Create New Supplier</span
                  >
                </div>
              </ng-template>
            </supy-combo-box>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label">
              <span i18n="@@suppliers.create.details.displayName">
                Supplier Display Name
                <sup>*</sup>
              </span>

              <supy-icon name="info" size="small" [supyTooltip]="supplierDisplayNameTooltip" position="top"></supy-icon>
            </span>

            <supy-input
              i18n-placeholder="@@suppliers.create.details.displayNamePlaceholder"
              placeholder="Enter Supplier Display Name"
              [disabled]="!isNewSupplier() && !getValue('supplier')?.length"
              formControlName="displayName"
            ></supy-input>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.taxNumber">
              Tax Registration Number
              <supy-icon name="info" size="small" [supyTooltip]="supplierTaxNumberTooltip" position="top"></supy-icon>
            </span>

            <supy-input
              [placeholder]="isNewSupplier() ? 'Enter Tax Registration Number' : 'Tax Registration Number'"
              formControlName="taxRegistrationNumber"
            ></supy-input>
          </div>
        </div>
      </div>

      <div class="supy-retailer-supplier-details__row supy-retailer-supplier-details__no-margin">
        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label">
              <span i18n="@@suppliers.create.details.country">
                Supplier Country
                <sup>*</sup>
              </span>
            </span>

            <supy-select
              placeholder="Select Country"
              i18n-placeholder="@@common.selectCountry"
              formControlName="country"
              name="country"
            >
              <supy-select-item *ngFor="let country of countries" [value]="country.id">{{
                country.name | titlecase
              }}</supy-select-item>
            </supy-select>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.comments">
              Remarks/Comments
            </span>

            <supy-input
              placeholder="Enter Comment"
              i18n-placeholder="@@common.commentPlaceholder"
              [disabled]="!isNewSupplier() && !getValue('supplier')?.length"
              formControlName="comments"
            ></supy-input>
          </div>
        </div>

        <div *ngIf="!isCKSupplierV1Enabled() && !isCKSupplierV2Enabled()" class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.hideLpoPrices">
              Hide prices on LPOs
            </span>
            <supy-switch formControlName="hideLpoPrices"></supy-switch>
          </div>
        </div>
      </div>
    </div>

    <section class="supy-retailer-supplier-details__section-container">
      <article class="supy-retailer-supplier-details__section">
        <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

        <supy-supplier-contacts-grid
          [contacts]="getValue('contactNumbers')"
          [enableAdding]="getValue('enableWhatsApp')"
          [contactType]="contactType.WhatsApp"
          [isAddingDisabled]="isAddingContactDisabled"
          [inputValidators]="phoneValidators"
          (addContact)="onAddContact($event, contactType.WhatsApp)"
          (removeContact)="onRemoveContact($event, contactType.WhatsApp)"
          subHeaderTitle="Send order whatsapp to the supplier through Supy"
          i18n-subHeaderTitle="@@suppliers.create.details.sendOrderWhatsapp"
          subHeaderDescription="Enabling this will send a whatsapp to the following numbers with the PO details created through Supy"
          i18n-subHeaderDescription="@@suppliers.create.details.sendOrderWhatsappDescription"
          noRowsOverlayMessage="Enable the toggle to add whatsapp numbers here to receive orders"
          i18n-noRowsOverlayMessage="@@suppliers.create.details.enableToggleWhatsApp"
        ></supy-supplier-contacts-grid>
      </article>

      <article class="supy-retailer-supplier-details__section">
        <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

        <supy-supplier-contacts-grid
          [contacts]="getValue('emails')"
          [enableAdding]="getValue('enableEmail')"
          [isAddingDisabled]="isAddingContactDisabled"
          [contactType]="contactType.Email"
          [inputValidators]="emailValidators"
          (addContact)="onAddContact($event, contactType.Email)"
          (removeContact)="onRemoveContact($event, contactType.Email)"
          subHeaderTitle="Send order email to the supplier through Supy"
          i18n-subHeaderTitle="@@suppliers.create.details.sendOrderEmail"
          subHeaderDescription="Enabling this will send an email to the following email addresses with the PO details created through Supy"
          i18n-subHeaderDescription="@@suppliers.create.details.sendOrderEmailDescription"
          noRowsOverlayMessage="Enable the toggle to add email addresses here to receive orders"
          i18n-noRowsOverlayMessage="@@suppliers.create.details.enableToggleEmail"
        ></supy-supplier-contacts-grid>
      </article>
    </section>

    <div class="supy-retailer-supplier-details__section">
      <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

      <div class="supy-retailer-supplier-details__header">
        <h5 class="supy-retailer-supplier-details__header--title" i18n="@@preferences">Preferences</h5>
      </div>

      <div class="supy-retailer-supplier-details__row-secondary supy-retailer-supplier-details__no-margin">
        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.minOrderLimit"
              >Minimum Order Limit
              <supy-icon name="info" size="small" [supyTooltip]="minimumOrderLimitTooltip" position="top"></supy-icon>
            </span>

            <supy-input
              i18n-placeholder="@@suppliers.create.details.minOrderLimitPlaceholder"
              placeholder="Enter Minimum Order Limit"
              type="number"
              formControlName="minimumOrderLimit"
            ></supy-input>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.paymentMethod"
              >Default Payment Method
            </span>
            <supy-select
              formControlName="paymentMethod"
              i18n-placeholder="@@suppliers.selectPaymentMethod"
              placeholder="Select Payment Method"
            >
              <supy-select-item *ngFor="let method of paymentMethods | keyvalue" [value]="method.value">{{
                method.value | titlecase
              }}</supy-select-item>
            </supy-select>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.cutOffTime">
              <span>Cut-off Time</span>
              <supy-icon name="info" size="small" [supyTooltip]="cutOffTimeTooltip" position="top"></supy-icon>
            </span>

            <supy-time-picker mode="dropdown" formControlName="cutOffTime"></supy-time-picker>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.feePercentage">
              <span>Handling Fee Percentage</span>
              <supy-icon name="info" size="small" [supyTooltip]="handlingFeeTooltip" position="top"></supy-icon>
            </span>

            <supy-input
              i18n-placeholder="@@suppliers.create.details.feePercentagePlaceholder"
              placeholder="Enter Handling Fee Percentage"
              type="number"
              formControlName="defaultHandlingFeePercentage"
            ></supy-input>
          </div>
        </div>

        <div *ngIf="!isTaxesHidden()" class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.defaultTaxRate"
              >Default Tax Rate</span
            >
            <supy-select
              name="defaultTaxId"
              formControlName="defaultTaxId"
              i18n-placeholder="@@suppliers.create.details.taxRatePlaceholder"
              placeholder="Select Tax"
              [clearable]="true"
            >
              <supy-select-item *ngFor="let tax of taxes; trackBy: trackTaxesById" [value]="tax?.id">
                {{ tax.name }}
              </supy-select-item>
            </supy-select>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.creditPeriod">
              <span>Credit Period</span>
              <supy-icon name="info" size="small" [supyTooltip]="creditPeriodTooltip" position="top"></supy-icon>
            </span>

            <supy-dropdown-tree
              [data]="creditPeriodTypes"
              name="creditPeriodType"
              formControlName="creditPeriodType"
              i18n-placeholder="@@suppliers.create.details.creditPeriodType"
              placeholder="Select Credit Period Type"
            ></supy-dropdown-tree>
          </div>
        </div>

        <div class="supy-retailer-supplier-details__col" [hidden]="!isCustomCreditPeriod">
          <div class="supy-retailer-supplier-details__input">
            <span class="supy-retailer-supplier-details__label" i18n="@@suppliers.create.details.creditPeriod">
              <span></span>
              <supy-icon name="info" size="small" [supyTooltip]="creditPeriodTooltip" position="top"></supy-icon>
            </span>

            <supy-counter-input
              [min]="0"
              [step]="1"
              [value]="0"
              (valueChange)="onCreditPeriodChange($event)"
              formControlName="creditPeriod"
            ></supy-counter-input>
          </div>
        </div>
      </div>
    </div>

    <div class="supy-retailer-supplier-details__section supy-retailer-supplier-details__section--branches">
      <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

      <div class="supy-retailer-supplier-details__header">
        <div class="supy-retailer-supplier-details__header-row">
          <div class="supy-retailer-supplier-details__header-text">
            <h5 class="supy-retailer-supplier-details__header--title" i18n="@@suppliers.create.details.activeLocations">
              Active Locations
            </h5>
            <span>-</span>
            <span i18n="@@common.amountLocationsSelected">{{ activeBranches.length }} selected</span>
          </div>

          <div class="supy-retailer-supplier-details__header-actions">
            <supy-button
              name="select-all"
              color="secondary"
              (buttonClick)="setAllBranches(true)"
              [disabled]="isSelectBranchDisabled() || canSelectAllBranchesDisabled"
            >
              <span i18n="@@common.actions.selectAll">Select All</span>
            </supy-button>

            <supy-button
              name="unselect-all"
              [disabled]="isSelectBranchDisabled() || canDeselectAllBranchesDisabled"
              color="secondary"
              (buttonClick)="setAllBranches(false)"
            >
              <span i18n="@@common.actions.unselectAll">Unselect All</span>
            </supy-button>
          </div>
        </div>
        <span class="supy-retailer-supplier-details__description" i18n="@@suppliers.create.details.selectLocationsHint">
          Select the locations that will be able to order from this supplier, along with preferences for specific
          locations.
        </span>
      </div>

      <div class="supy-retailer-supplier-details__branches-grid-container">
        <supy-supplier-branches-grid
          [branches]="getValue('branches') ?? []"
          [supplier]="supplierObject()"
          [currency]="currency"
          [currencyPrecision]="currencyPrecision"
          [isSelectingBranchDisabled]="isSelectBranchDisabled()"
          [showPreferredColumn]="showPreferredColumn"
          [showCreditPeriodValueColumn]="isCreditPeriodValueColumnVisible"
          (branchesChanged)="onBranchesChange()"
        ></supy-supplier-branches-grid>
      </div>
    </div>
  </div>

  <div class="supy-retailer-supplier-details__section" *ngIf="canUpdateChannel">
    <ng-container *ngTemplateOutlet="loading; context: { isLoading: initialLoading }"></ng-container>

    <div class="supy-retailer-supplier-details__footer">
      <supy-button
        *ngIf="isExistingSupplier() && !(isCKSupplierV1Enabled() || isCKSupplierV2Enabled())"
        color="error-secondary"
        (buttonClick)="onDeleteSupplier()"
        name="delete-supplier"
        i18n="@@deleteSupplier"
      >
        Delete Supplier
      </supy-button>
      <supy-button color="primary" [disabled]="!isFormValid" (buttonClick)="onSubmit()">
        <supy-loader *ngIf="isLoading"></supy-loader>
        <span i18n="@@common.actions.saveDetails">Save Details</span>
      </supy-button>
    </div>
  </div>
</form>

<supy-tooltip #multipleItemsTooltip i18n="@@suppliers.create.details.multipeItemsTooltip"
  >You can enter multiple inputs by entering them one by one.</supy-tooltip
>

<supy-tooltip #cutOffTimeTooltip i18n="@@suppliers.create.details.cutOffTimeTooltip"
  >The cut-off time before which you must submit your orders with this supplier to receive them the next working
  day</supy-tooltip
>

<supy-tooltip #creditPeriodTooltip i18n="@@suppliers.create.details.creditPeriodTooltip"
  >Used to calculate the payment date for your invoices</supy-tooltip
>

<supy-tooltip #handlingFeeTooltip i18n="@@suppliers.create.details.feePercentageTooltip"
  >The percentage that is added to the receiving item price due to handling fees, if any</supy-tooltip
>

<supy-tooltip #supplierDisplayNameTooltip i18n="@@suppliers.create.details.displayNameTooltip"
  >The name you will see across the system</supy-tooltip
>

<supy-tooltip #supplierTaxNumberTooltip i18n="@@suppliers.create.details.taxNumberTooltip"
  >This is the Supplier’s Tax Number for your country</supy-tooltip
>

<supy-tooltip #minimumOrderLimitTooltip i18n="@@suppliers.create.details.minOrderLimitTooltip"
  >The minimum value of an order that supplier accepts</supy-tooltip
>

<ng-template #loading let-isLoading="isLoading">
  <div class="supy-retailer-supplier-details__section--loading" *ngIf="isLoading" @fadeOut></div>
</ng-template>
