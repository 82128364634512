import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';

import {
  ButtonModule,
  ComboboxModule,
  CounterInputModule,
  DropdownModule,
  DropdownTreeModule,
  FiltersModule,
  GridPocModule,
  IconModule,
  ImageUploaderModule,
  InputModule,
  LoaderModule,
  LoadingOverlayModule,
  SelectModule,
  SeparatorModule,
  SwitchModule,
  TimePickerModule,
  TooltipModule,
} from '@supy/components';
import { BranchesService } from '@supy/retailers';

import {
  RetailerSupplierDetailsComponent,
  RetailerSupplierItemsComponent,
  RetailerSupplierItemsGridComponent,
  SupplierBranchesGridComponent,
  SupplierContactsGridComponent,
  SupplierFormComponent,
} from './components';
import {
  SupplierResolver,
  SupplierUsersResolver,
  UserRelatedSuppliersResolver,
  UserRetailersRelatedSuppliersResolver,
} from './resolvers';
import { SuppliersState } from './store';

const COMPONENTS = [
  RetailerSupplierItemsComponent,
  RetailerSupplierItemsGridComponent,
  SupplierContactsGridComponent,
  SupplierFormComponent,
  RetailerSupplierDetailsComponent,
  SupplierBranchesGridComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SwitchModule,
    SelectModule,
    TimePickerModule,
    IconModule,
    ImageUploaderModule,
    ButtonModule,
    GridPocModule,
    DropdownModule,
    FiltersModule,
    LoadingOverlayModule,
    SeparatorModule,
    TooltipModule,
    ComboboxModule,
    InputModule,
    CounterInputModule,
    LoaderModule,
    NgxsModule.forFeature([SuppliersState]),
    DropdownTreeModule,
  ],
  declarations: [...COMPONENTS],
  providers: [
    SupplierResolver,
    UserRelatedSuppliersResolver,
    UserRetailersRelatedSuppliersResolver,
    SupplierUsersResolver,
    BranchesService,
  ],
  exports: [...COMPONENTS],
})
export class SuppliersModule {}
